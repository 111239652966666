import React, { Component } from 'react';
import './Join.css';
import { VegaButton } from '@heartlandone/vega-react';

class Join extends Component {
  constructor(props) {
    super(props);
    this.state = {
      myName: this.props.playerName,
    };
  }

  handleChange = (e) => {
    // eslint-disable-line
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    // eslint-disable-line
    e.preventDefault();
    this.props.onSubmit(this.state.myName);
  };

  render() {
    return (
      <div className="Join">
        <form className="Form" onSubmit={this.handleSubmit}>
          <h1>
            GP<strong>Poker</strong>
          </h1>
          <div className="collect-play-name-container">
            <span className="text">Observe or</span>
            <input
              type="text"
              name="myName"
              value={this.state.myName}
              onChange={this.handleChange}
              placeholder="type in your name"
              className="v-text-primary"
              required
            />
            <span className="text">to</span>

            <VegaButton
              type="submit"
              className="vega-button-size-default vega-button-variant-primary"
            >
              Play
            </VegaButton>
          </div>
        </form>
      </div>
    );
  }
}

export default Join;
