import React, { Component } from 'react';
import './Share.css';
import { VegaTooltip } from '@heartlandone/vega-react';

const defaultTooltip = 'Click to copy link';

class Share extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltip: defaultTooltip,
    };
  }

  handleCopy = (e) => {
    // eslint-disable-line
    e.preventDefault();
    this.textInput.select();
    document.execCommand('copy');
    this.setState({ tooltip: 'Copied!' });
  };

  handleDone = (e) => {
    // eslint-disable-line
    this.setState({ tooltip: defaultTooltip });
  };

  render() {
    const { roomName } = this.props;
    const caption = `${process.env.REACT_APP_DOMAIN}/${roomName}`;
    const link = `${window.location.protocol}//${caption}`;

    return (
      <div className="Share">
        <h1>
          GP<strong>Poker</strong>
        </h1>
        <VegaTooltip
          size="S"
          content={this.state.tooltip}
          trigger="hover"
          placement="right"
          alignment="start"
        >
          <input
            type="text"
            value={link}
            ref={(input) => {
              this.textInput = input;
            }}
            readOnly
          />
          <a
            href={link}
            onClick={this.handleCopy}
            onMouseLeave={this.handleDone}
            aria-label={this.state.tooltip}
          >
            {caption}
          </a>
        </VegaTooltip>
      </div>
    );
  }
}

export default Share;
