import React, { Component } from 'react';
import { hri } from 'human-readable-ids';
import { VegaInput, VegaButton } from '@heartlandone/vega-react';
import './Landing.css';

class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roomName: '',
      randomRoomName: hri.random(),
    };
  }

  handleChange = (e) => {
    // eslint-disable-line
    this.setState({
      roomName: e.target.value,
    });
  };

  handleSubmit = (e) => {
    // eslint-disable-line
    e.preventDefault();
    const roomName = this.state.roomName || this.state.randomRoomName;
    this.props.history.push(roomName);
  };

  render() {
    return (
      <div className="Landing ">
        <form className="Form" onSubmit={this.handleSubmit}>
          <h1>
            GP<strong>Poker</strong>
          </h1>
          <div className="router-link-input-container">
            <div className="router-link-input">
              <VegaInput
                style={{ width: '300px', marginLeft: '8px' }}
                value={this.state.roomName}
                placeholder={this.state.randomRoomName}
                type="text"
                onVegaChange={this.handleChange}
              ></VegaInput>
            </div>
            <VegaButton
              type="submit"
              className="vega-button-size-default vega-button-variant-primary"
            >
              Join
            </VegaButton>
          </div>
        </form>
      </div>
    );
  }
}

export default Landing;
