import React from 'react';
import ReactDOM from 'react-dom';
import App from 'pages/App/App';
import '@heartlandone/vega/style/vega.css';
import './svg/icons.css';

import registerServiceWorker from './registerServiceWorker';

import './index.css';

ReactDOM.render(<App />, document.getElementById('root'));

registerServiceWorker();
